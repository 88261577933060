<template>
  <div class="overDetails" v-loading="loading">
    <div class="content">
      <Breadcrumb></Breadcrumb>
      <!-- 顶部分数 -->
      <!-- <div class="grade">
        <div class="grade-item" :style="{ background: v.background }" v-for="(v, i) in gradeList" :key="i">
          <div class="tag">{{ v.tag }}</div><div class="num">{{ v.num }}</div>
        </div>
      </div> -->

      <div class="box">
        <div class="content-left">
          <!-- 折线图 -->
          <!-- <div class="bar-chart">
            <div class="title">分数段</div>
            <div id="chart" style="width: 100%; height: 400px"></div>
          </div> -->
          <!-- <Grid v-bind="grid" class="table">
            <el-table-column label="题目名称" align="center" slot="name">
              <template slot-scope="scope">
                <span style="color: #0d9b85" v-html="scope.row.title"></span>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" fixed="right" slot="operate">
              <template slot-scope="scope">
                <el-button type="text" @click="handleDetails(scope.row)" style="color: #0d9b85">查看详情</el-button>
              </template>
            </el-table-column>
          </Grid>
          <Grid v-bind="rankingList" class="table"> </Grid>
          <div class="download" v-if="['比赛' , '考试' , '作业'].indexOf($route.query.type) > -1">
            <div class="button-primary" :style="{ marginLeft:'1rem',padding:'4px 20px'}" @click="download">
              下载
            </div>
          </div> -->
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <el-button type="primary" size="mini" @click="$router.back()"
              >返 回</el-button
            >
            <div style="display: flex; align-items: center">
              <div
                style="
                  margin-right: 10px;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 30px;
                "
              >
                答题时间段:
              </div>
              <el-date-picker
              
                v-model="value"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
              <el-button
                type="primary"
                size="mini"
                style="margin-left: 20px"
                @click="search"
                >搜 索</el-button
              >
              <el-button size="mini" type="primary" @click="download"
                >下载</el-button
              >
            </div>
          </div>
          <div style="margin-top: 20px;width: 100%; overflow-x: auto;">
            <el-table
              :data="tableData"
              v-loading="tableLoading"
              height="650px"
              style="width: 100%"
              fit
              :default-sort="{ prop: 'grade_count', order: 'descending' }"
            >
              <el-table-column
                type="index"
                label="序号"
                width="60"
                align="center"
              ></el-table-column>

              <el-table-column prop="nickname" width="80" show-overflow-tooltip label="姓名" align="center">
              </el-table-column>
              <el-table-column
                prop="school_name"
                label="学校"
                align="center"
              >
              </el-table-column>
                
              <el-table-column prop="grade_count" width="180" label="成绩" align="center">
                <template slot-scope="scope">
                  <span>{{scope.row.grade_count+'/'+ scope.row.list_total_grade}}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="last_answer_time"
                label="答题时间"
                width="180"
                align="center"
              >
              </el-table-column>

              <el-table-column
                v-for="(v, i) in headers"
                :key="i"
                :prop="v.title"
                :label="v.title"
                width="150"
                align="center"
              >
                <template #header>
                  <el-tooltip :content="v.title" placement="top">
                    <div style="width: 150px;" v-html="v.title"></div>
                  </el-tooltip>
                  <div>总分:{{ v.grade }}</div>
                </template>
                <template slot-scope="scope">
                  <div style="display: flex; justify-content: space-around">
                    <div>
                      {{ scope.row.question_list[i]?.grade }}
                    </div>
                    <el-button size="mini" type="primary" @click="checkCode(scope.row.question_list[i],scope.row)">查看答案</el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>

            <div
              class="pagination"
              style="
                margin-top: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <el-pagination
                background
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                @current-change="currentChange"
                @size-change="handleSizeChange"
                :page-size="pageSize"
                :current-page="page"
              >
              </el-pagination>
            </div>
          </div>
        </div>
        <!-- <div class="content-right">
          <Advertising></Advertising>
          <HotRecommend class="margin"></HotRecommend>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Advertising from "@/components/Advertising/Advertising.vue";
import HotRecommend from "@/views/api/com/hotRecommend";
import * as echarts from "echarts";
import Grid from "@/components/Grid/index.vue";
import {
  statistics,
  statistics_user,
  bisaiStatistics,
  bisaiStatistics_user,
  rank_log_down,
} from "@/api/problemList.js";
export default {
  components: {
    Advertising,
    HotRecommend,
    Grid,
  },
  data() {
    return {
      rankingList: {
        source: [],
        columns: [
          {
            prop: "rank",
            label: "名次",
          },
          {
            prop: "nickname",
            label: "姓名",
          },
          {
            prop: "grade",
            label: "得分",
          },
          {
            prop: "duration",
            label: "用时",
          },

          {
            prop: "right_pro",
            label: "正确率",
          },
          {
            prop: "grade_level",
            label: "等级",
          },
        ],
        page: false,
      },
      grid: {
        source: [],
        columns: [
          {
            slot: "name",
          },
          {
            prop: "type_title",
            label: "类型",
          },
          {
            prop: "right_pro",
            label: "正确率",
          },
          {
            slot: "operate",
          },
        ],
        page: false,
      },
      gradeList: [
        {
          tag: "平均分",
          num: 0,
          background: "#69bf6e",
          title: "avg_grade",
        },
        {
          tag: "最高分",
          num: 0,
          background: "#22D8FF",
          title: "max_grade",
        },
        {
          tag: "最低分",
          num: 0,
          background: "#FFA32E",

          title: "min_grade",
        },
        {
          tag: "及格率",
          num: 0,
          background: "#8F5CD0",
          title: "jige_pre",
        },
        {
          tag: "优秀率",
          num: 0,
          background: "#43C5BC",
          title: "youxiu_pre",
        },
      ],
      id: "",
      chartData: [],
      loading: false,
      tableData: [],
      total: 0,
      page: 1,
      pageSize: 50,
      headers: [],
      end_time: "",
      other_id: 0,
      start_time: "",
      value: [],
      group_id: 0,
      tableLoading: false,
    };
  },
  mounted() {},
  created() {
    this.other_id = this.$router.currentRoute.query.id;
    this.group_id = this.$router.currentRoute.query.group_id;

    this.checkTotal();
  },
  methods: {
    routeUpdate() {
      let { id, type } = { ...this.$route.query, ...this.$route.params };
      this.id = id;
      // this.loading = true;
      console.log(this.$route.query, "this.$route.query");
      // let apis = [statistics, statistics_user];

      // if (["比赛", "考试", "作业"].indexOf(type) > -1) {
      // apis = [bisaiStatistics, bisaiStatistics_user];
      // if (this.$route.name !== "matchOverDetails") {
      //   this.$handleRoute(
      //     { ...this.$route.query, ...this.$route.params },
      //     "matchOverDetails"
      //   );
      // }
      // }
      // if (["比赛"].indexOf(type) > -1) {
      //   this.gradeList = [
      //     {
      //       tag: "最高分",
      //       num: 0,
      //       background: "#22D8FF",
      //       title: "max_grade",
      //     },

      //     {
      //       tag: "参赛总人数",
      //       num: 0,
      //       background: "#8F5CD0",
      //       title: "count",
      //     },
      //   ];
      // }
      // apis[0]({ id: this.id })
      //   .then(({ data }) => {
      //     console.log(data);
      //     for (let v in data.grade) {
      //       this.gradeList.map((j) => {
      //         if (v == j.title) {
      //           j.num = data.grade[v];
      //         }
      //       });
      //     }
      //     this.chartData = data.statistics_grade;
      //     this.grid.source = data.quest_list;
      //     // this.initChart()
      //   })
      //   .finally(() => {
      //     this.loading = false;
      //   });
      // apis[1]({ id: this.id }).then(({ data }) => {
      //   this.rankingList.source = data.data;
      // });
    },

    // 查看答题统计
    checkTotal() {
      this.tableLoading = true;
      this.$http
        .get("/api/v1_0_0.question_list/statistics_user", {
          params: {
            group_id: this.group_id,
            other_id: this.other_id,
            pageSize: this.pageSize,
            page: this.page,
            start_time: this.start_time,
            end_time: this.end_time,
          },
        })
        .then((res) => {
          this.tableLoading = false;

          console.log(res, "查看答题统计");
          this.tableData = res.data.list;
          this.total = res.data.total;
          console.log(this.tableData, "this.tableDatathis.tableData");
          // this.headers = res.data.question_title;
          this.headers = res.data.quest_title;
        })
        .catch(({ code, msg }) => {
          console.log(code);
          console.log(msg);
        });
    },

    // 查看单个题目代码
    checkCode(item,e) {
      console.log(item,"item")
      // return
      this.$handleRoute({log_id:item.log_id,id:item.qid} , 'topicRecord' , '_blank')
      console.log(e, "eeeee");
    },
    // submit(value) {

    // },

    search() {
      console.log(this.value, "时间");
      if (this.value && this.value.length > 0) {
        this.start_time = this.value[0];
        this.end_time = this.value[1];
      } else {
        this.start_time = "";
        this.end_time = "";
      }
      console.log(this.start_time, "this.start_time");
      console.log(this.end_time, "this.end_time");
      this.checkTotal();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.checkTotal();
    },
    currentChange(e) {
      this.page = e;
      this.checkTotal();
    },
    //下载
    download() {
      window.location.href =
        (location.baseURL || "") +
        `/api/v1_0_0.question_other/rank_log_down?other_id=${
          this.other_id
        }&type=list&user_id=${localStorage.getItem("user_id")}&user_token=` +
        encodeURIComponent(localStorage.getItem("user_token"));
    },
    handleDetails({ question_id, type_title }) {
      let { id, type } = { ...this.$route.query, ...this.$route.params };
      if (["比赛", "考试", "作业"].indexOf(type) > -1) {
        this.$handleRoute(
          { id: question_id, type_title },
          "matchOverTopicDetails"
        );
      } else {
        this.$router.push({
          path: "/problemList/overTopicDetails",
          query: { id: question_id, type_title },
        });
      }
    },
    initChart() {
      let xdata = Object.keys(this.chartData);
      let ydata = Object.values(this.chartData);
      let myChart = echarts.init(document.getElementById("chart"));
      let option = {
        tooltip: {
          trigger: "item",
        },
        grid: {
          left: "6%",
          right: "6%",
          top: "16%",
          bottom: "6%",
        },
        xAxis: {
          name: "分数段",
          type: "category",
          data: xdata,
        },
        yAxis: {
          name: "人数",
          type: "value",
        },
        series: [
          {
            data: ydata,
            type: "bar",
            color: "#00957E",
            barWidth: 42,
          },
        ],
      };
      option && myChart.setOption(option);
    },
  },
};
</script>
<style lang="less">
 .el-picker-panel__footer{
   .is-plain{
    background: #00957E !important;
    color: #ffffff !important;
  }
  }
</style>
<style lang="less" scoped>
.overDetails {
  .download {
    margin: 1rem;
    > div {
      float: right;
    }
  }
  .grade {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    // margin-bottom: 40px;
    .grade-item {
      margin-right: 2rem;
      width: calc(20% - 1.6rem);
      padding: 2rem 10px;
      background: #69bf6e;
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.5);
      border-radius: 10px;
      color: #fff;
      justify-content: center;
      display: flex;
      align-items: flex-end;
      .tag {
        font-size: 18px;
        margin-bottom: 0.1rem;
      }
      .num {
        font-weight: 600;
        font-size: 52px;
        line-height: 1;
        margin-left: 0.5rem;
      }
    }
    .grade-item:last-child {
      margin-right: 0;
    }
  }
  .box {
    display: flex;
    justify-content: space-between;

    .content-left {
      // width: 70%;
      width: 100%;
      .bar-chart {
        width: 100%;
        box-sizing: border-box;
        // height: 400px;
        background: #ffffff;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
        border: 1px solid #ffffff;
        padding: 2rem;
        .title {
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.85);
          line-height: 16px;
        }
      }
    }
    .content-right {
      width: 27%;
      .margin {
        margin-top: 40px;
      }
    }
  }
}
</style>
